<template>
    <b-dropdown
        id="dropdown-form"
        :text="t('filters')"
        variant="secondary"
        ref="dropdown"
        class="mr-2"
        :boundary="boundary"
        @show="formStateCatch"
        @hide="filterCatch"
    >
        <b-dropdown-form>
            <b-form-group
                :label="t('surgeon')"
                label-for="dropdown-form-surgeon"
                @submit.stop.prevent
            >
                <Selector
                    id="dropdown-form-surgeon"
                    :placeHolder="t('selectSurgeon')"
                    :options="surgeonsOptions"
                    :propertiesToSearch="['optionDisplayText']"
                    :required="false"
                    :state="isNil(filters.surgeon) ? null : true"
                    optionValueKey="doctorId"
                    optionDisplayKey="optionDisplayText"
                    optionDisabledKey="optionDisabled"
                    v-model="filters.surgeon"
                >
                    <template #option="{option}">
                        <div class="patient-filters-surgeon-selector-option">
                            <SearchedText
                                class="ml-1"
                                :disabled="option.optionDisabled"
                                :searchedItem="option"
                                searchedProperty="optionDisplayText"
                            ></SearchedText>
                        </div>
                    </template>
                    <template #no-options-found> {{ t('noSurgeonFound') }}</template>
                </Selector>
            </b-form-group>

            <b-form-group :label="t('createdBy')" label-for="dropdown-form-createdby">
                <b-form-input
                    id="dropdown-form-createdby"
                    :state="filters.createdBy ? true : null"
                    :placeholder="t('createdBy')"
                    v-model="filters.createdBy"
                ></b-form-input>
            </b-form-group>

            <b-form-group>
                <b-form-row>
                    <b-col>
                        <b-form-checkbox
                            v-model="filters.hideOrdered"
                            class="no-wrap"
                            name="check-button"
                            :value="1"
                            :unchecked-value="null"
                            switch
                        >
                            {{ t('hideOrdered') }}
                        </b-form-checkbox>
                    </b-col>
                    <b-col>
                        <b-form-checkbox
                            v-model="filters.hideInCart"
                            class="no-wrap"
                            name="check-button"
                            :value="1"
                            :unchecked-value="null"
                            switch
                        >
                            {{ t('hideInCart') }}
                        </b-form-checkbox>
                    </b-col>
                </b-form-row>
            </b-form-group>

            <b-form-group>
                <b-form-row>
                    <b-col>
                        <b-form-checkbox
                            v-model="filters.showCancelled"
                            class="no-wrap"
                            name="check-button"
                            :value="1"
                            :unchecked-value="null"
                            switch
                        >
                            {{ t('showCanceled') }}
                        </b-form-checkbox>
                    </b-col>
                </b-form-row>
            </b-form-group>

            <b-form-row>
                <b-col>
                    <b-button variant="secondary" @click="cancel"> {{ t('cancel') }}</b-button>
                </b-col>
                <b-col class="d-flex justify-content-end">
                    <b-button
                        class="mr-2"
                        variant="secondary"
                        :disabled="!canCleanFilters"
                        @click="clear"
                    >
                        {{ t('clear') }}</b-button
                    >
                    <b-button variant="primary" :disabled="hasFormChanged" @click="apply">
                        {{ t('apply') }}</b-button
                    >
                </b-col>
            </b-form-row>
        </b-dropdown-form>
    </b-dropdown>
</template>

<script>
import {mapGetters} from 'vuex';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import Selector from '@/components/Selector';
import SearchedText from '@/components/SearchedText.vue';
import {surgeonIdNameFormat} from '@/utilities/formatters';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

const defaultFilters = (isDistributorSurgeonMode, isDistributorMode) => ({
    surgeon: null,
    createdBy: null,
    showCancelled: null,
    hideOrdered: isDistributorSurgeonMode || isDistributorMode ? 1 : null,
    hideInCart: null,
});

export default {
    name: 'ReserveFilters',
    components: {Selector, SearchedText},
    data() {
        return {
            filters: {},
            formState: JSON.stringify({}),
            boundary: document.getElementById('app'),
        };
    },

    computed: {
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('permissions', ['permissions']),
        ...mapGetters({
            doctors: 'doctors/list',
        }),
        isDistributorMode() {
            return this.checkPermissions({
                [PERMISSIONS.DISTRIBUTOR_MODE]: [PERMISSIONS_VALUES.DISTRIBUTOR],
            });
        },
        isDistributorSurgeonMode() {
            return this.checkPermissions({
                [PERMISSIONS.DISTRIBUTOR_MODE]: [PERMISSIONS_VALUES.SURGEON],
            });
        },
        surgeonsOptions() {
            const options = this.doctors.map((s) => ({
                doctorId: s.doctorId,
                optionDisplayText: this.surgeonIdNameFormat(
                    s?.contactPartyNumber || 'N/A',
                    `${s?.firstName || 'N/A'} ${s?.lastName || 'N/A'}`,
                    s.active
                ),
                optionDisabled: false,
            }));

            options.unshift({
                doctorId: 0,
                optionDisplayText: this.t('noSurgeon'),
                optionDisabled: false,
            });

            return options;
        },
        canCleanFilters() {
            return Object.values(this.filters).some((x) => !isNil(x));
        },
        hasFormChanged() {
            return this.formState == JSON.stringify(this.filters);
        },
    },

    methods: {
        isNil,
        surgeonIdNameFormat,
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        formStateCatch() {
            this.formState = JSON.stringify(this.filters);
        },
        filterCatch() {
            this.filters = JSON.parse(this.formState);
        },
        cancel() {
            this.$refs.dropdown.hide(true);
        },
        clear() {
            this.filters = defaultFilters(this.isDistributorSurgeonMode, this.isDistributorMode);
            this.apply();
            this.cancel();
        },
        apply() {
            this.$emit('change', this.filters);
            this.formStateCatch();
            this.cancel();
        },
    },

    mounted() {
        this.$store.dispatch('doctors/fetchList', {
            currentPage: 1,
            perPage: 0, // fetch all the doctors
        });
        this.filters = defaultFilters(this.isDistributorSurgeonMode, this.isDistributorMode);
        this.formState = JSON.stringify(this.filters);
        this.filters = merge(
            {},
            this.filters,
            pick(this.$route.query, Object.keys(defaultFilters()))
        );
    },
};
</script>
<style></style>
